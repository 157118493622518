<template>
    <div class="flex flex-col justify-around my-20">
        <div class="flex flex-col bg-beige-200 p-2 text-white justify-center
            items-center w-screen lg:w-1/2 mx-auto rounded-none lg:rounded-md shadow">
            <p class="font-extrabold text-xl">
            {{$t('registration.activation')}}
            </p>
            <div class="flex flex-col text-white mt-8">
              <span>
                {{ warranty.harp_description }} {{ warranty.harp_config_description }}
              </span>
              <span class="font-extrabold font-number text-lg">{{ warranty.serial_no }}</span>
            </div>
            <div class="flex flex-col lg:flex-row lg:justify-around w-11/12 lg:w-full pb-5 mt-3">
              <div class="flex flex-col items-center">
                <p class="text-sm mt-3">
                  {{$t('registration.purchased-from')}}
                </p>
                <div v-if="warranty.dealer" class="bg-beige-100 text-beige-200 p-3 rounded w-64">
                  <span class="font-bold flex flex-wrap justify-center">
                    {{ warranty.dealer.company }}</span>
                  <div class="flex flex-wrap justify-center">
                    <p>{{ warranty.dealer.address1 }} <br>
                      {{ warranty.dealer.postcode }} {{ warranty.dealer.city }}
                      <span class="uppercase">  ({{ warranty.dealer.country }}) </span>
                    </p>
                  </div>
                  <div v-if="warranty.dealer_name">
                    <span class="font-bold">{{ warranty.dealer_name }}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center">
                <p class="text-sm mt-3">
                  {{$t('registration.customer-details')}}
                </p>
                <div class="bg-beige-100 text-beige-200 p-3 rounded w-64">
                  <span class="font-bold flex flex-wrap justify-center">
                    {{ warranty.customer.company }}</span>
                  <div class="flex flex-wrap justify-center">
                    <p>{{ warranty.customer.address1 }} <br>
                      {{ warranty.customer.postcode }} {{ warranty.customer.city }}
                      <span class="uppercase">  ({{ warranty.customer.country }}) </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col lg:flex-row my-3 justify-around w-full">
              <div class="flex flex-col mb-8 lg:mb-0">
                <label class="font-bold uppercase mb-2">
                  {{$t('registration.sale-date')}}
                </label>
                <span class="font-number">{{ warranty.sales_date }}</span>
              </div>
              <div class="flex flex-col">
                <label class="font-bold uppercase mb-2">
                  {{$t('registration.end-warranty')}}
                </label>
                <span class="font-number">{{ warranty.end_warranty_date }}</span>
              </div>
            </div>
            <p class="text-sm mt-3">
              {{$t('registration.confirmation-mail')}}
            </p>
        </div>

        <!-- <div class="flex justify-center mt-40 items-center">
            <label class="mr-10 font-semibold">
                {{$t('newsletter.subscribe')}}
            </label>
            <div class="inline-block relative w-72">
                <input :placeholder="$t('newsletter.mail')"
                class="rounded-full border-2 border-beige-200 p-3 w-72 font-number
                    shadow-xl"/>
                <button class="absolute inset-y-0 right-0 flex items-center pr-3 pl-2">
                   <arrow></arrow>
                </button>
            </div>
        </div> -->
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
// import arrow from '../svg/arrow.vue';

export default {

  setup() {
    const store = useStore();

    const warranty = computed(() => store.getters.warranty[0]);

    return {
      warranty,
    };
  },

  components: {

  },
};
</script>

<style scoped>
    .shadow {
    --tw-shadow: 0 12px 15px 3px rgb(116, 112, 108), 0 4px 6px -2px rgb(116, 112, 108);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(61, 58, 58, 0.856)),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
</style>
